export default function scrollEvent() {

  // コールバック関数のオプション
  const options = {
    root: null,
    rootMargin: '-80% 0px 0px',
    threshold: 0
  }

  // オブジェクト生成
  const obs = new IntersectionObserver(showIntersect, options)

  // 監視要素を指定
  const imgs = [...document.querySelectorAll('.parent')];
  imgs.forEach(img => obs.observe(img))

  // 交差が閾値を超えたらクラスを付与する関数
  function showIntersect(changes) {
  changes.forEach(change => {
    if (change.isIntersecting) {
      change.target.classList.add('show');
      }
    })
  }

}
