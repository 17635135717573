import "@babel/polyfill";
import "picturefill";
import objectFitImages from "object-fit-images";
import "intersection-observer";

/* Modules
==================== */

// setVh
import setVh from "./modules/setVh";
setVh();

// ドロワー
import drawer from "./modules/drawer";
drawer();

// ロードアニメーション
import loadAnimation from "./modules/loadAnimation";
loadAnimation();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// スクロールイベント
import kvBg from "./modules/kvBg";
kvBg();

// スクロールイベント
import scrollEvent from "./modules/scrollEvent";
scrollEvent();


/* Script
==================== */
objectFitImages();
