import Swiper from 'swiper';

const el = '.swiper-container';
const options = {
  loop: true,
  effect: 'fade',
  speed: 2600,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false
  }
};

export const mySwiper = document.querySelector(el)
 ? new Swiper(el, options)
 : false;
