import { mySwiper } from "./swiper";

export default function loadAnimation() {
  const loadEl = document.getElementById(`load`);
  // アニメーションを一旦オフ
  // swiper();
  // if (loadEl) loadEl.style.display = `none`;
  // #loadがある場合にloadAnimationFuc()を実行する。
  if (loadEl) loadAnimationFuc(loadEl);
}

const title = document.getElementById(`js_title`);
const SHOW_CLASS = `is-show`;
const HIDE_CLASS = `is-hidden`;

const loadAnimationFuc = el => {
  console.log(title);
  // fixedbody(true);
  // ロードが終わったら
  mySwiper.autoplay.stop();
  window.addEventListener(`load`, _event => {
    mySwiper.autoplay.start();
    setTimeout(() => {
      // fixedbody(false);
      // title,subTieltにis-showを付与する
      title.classList.add(SHOW_CLASS);
      // Swiperを生成する。
    }, 500);
    // titleのアニメーションが終わったら
    title.addEventListener(`transitionend`, _event => {
      // #loadにis-hiddenを付与する。
      setTimeout(() => {
        el.classList.add(HIDE_CLASS);
      },80)
    });
    // #loadのアニメーションが終わったら
    el.addEventListener(`animationend`, _event => {
      // titleとsubTitleのz-indexを調整する。
      title.style.zIndex = 1;
    });
  });
};

const body = document.body;
/**
 * ボディを固定するの関数
 * @param  {boolean} status true||false
 * @return {void} trueの場合bodyにpostion:fixed,top:0,left:0を付与する
 */
const fixedbody = status => {
  if (status) {
    body.style.position = `fixed`;
    body.style.left = `0`;
    body.style.top = `0`;
  } else {
    body.style.left = ``;
    body.style.position = ``;
    body.style.top = ``;
  }
};

