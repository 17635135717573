export default function menu(kvBg) {

  $(window).scroll(function() {
      if ($(this).scrollTop() > 100) {
          $("#js_bg").addClass("is-test");
      }
      else {
        $("#js_bg").removeClass("is-test");
      }
  });
}
